import { Injectable } from '@angular/core';
import { HttpClient } from '../../common/http-client';

import { environment } from 'environments/environment';
import { ConfigService } from '../config.service';
import { Options } from 'app/models/models';

@Injectable()
export class ApplicationOptionsService {
    private _initialized = false;
    public options: Options;

    constructor(private _http: HttpClient, private configService: ConfigService) { }

    public async initialize() {
        if (this._initialized) {
            console.info('ApplicationOptionsService already initialized');
            return;
        }

        this.options = await this._http.get<Options>(`${this.optionsUrl}`).toPromise()
        this._initialized = true;
    }
    public get initialized() {
        return this._initialized;
    }
    
    private get optionsUrl(): string {
        const baseUrl = this.configService.config.serviceUrl;
        return environment.localTesting ? `${baseUrl}options/` : `${baseUrl}asws/profishvb/shear_connector_options/v1/`;
    }
}
