
import { AppStorageService } from './../util/app-storage.service';
import { StateGenerator } from './../../common/StateGenerator';
import { AppConstants } from './../../common/AppConstants';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { CanActivate } from '@angular/router';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LongregistrationService } from './longregistration.service';
import { ApplicationOptionsService } from './application-options.service';


@Injectable()
export class RouteGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private applicationOptionsService: ApplicationOptionsService,
    private longRegistrationService: LongregistrationService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.longRegistrationService.isLongRegistrationNeeded()) {
      this.longRegistrationService.redirectToLongRegistration();
    }
    if (!environment.localTesting) {
      if (!this.userService.isAuthenticated) {
        location.assign(this.authService.getAuthURL());
      } else {
        await this.applicationOptionsService.initialize();
        return true;
      }
    } else if (environment.localTesting && this.userService.isAuthenticated) {
      await this.applicationOptionsService.initialize();
      return true;
    }
  }
}
