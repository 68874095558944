import { map, mergeMap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { SessionStorageService } from '../service/util/session-storage.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationOptionsService } from 'app/service/common/application-options.service';

@Component({
    selector: 'tips',
    templateUrl: './tips.component.html',
    styleUrls: ['./tips.component.scss']
})
export class TipsComponent {
    constructor(
        public http: HttpClient,
        public sessionStorageService: SessionStorageService,
        public translate: TranslateService,
        public settingsService: SettingsService,
        private applicationOptionsService: ApplicationOptionsService
    ) {

    }

    getCurrentUrls(): Observable<any> {
        const countries = this.applicationOptionsService.options.nationalAnnexes;
        return this.settingsService.getSettings().pipe(mergeMap(defaultSettings => {
            const currentCountry = countries.filter(c => c.id === defaultSettings.countryNationalAnnexes)[0].labelKey;
            return this.http.get(`./assets/url/${currentCountry.toLowerCase()}-url-setting.json`).pipe(map((res: Response) => res));
        }));
    }
}
