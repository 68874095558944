
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReleaseNote } from '../models/models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})

export class WhatsNewComponent {

  public releaseNotes: ReleaseNote[] = [];
  constructor(private translate: TranslateService) {
    this.releaseNotes.push({
      title: 'Release 2.27 - September 2024',
      content: [ 'Bug fixes', 'Welcome to application translation update'], // No translation
      isCollapsed: false
    });
    this.releaseNotes.push({
      title: 'Release 2.26 - September 2024',
      content: ['Introduction of new countries: Sweden, Ireland', 'New decks for Sweden', 'Bug fixes for settings', 'Updated tranlations for Slovak language'], // No translation
      isCollapsed: true
    });

    this.releaseNotes.push({
      title: 'Release 2.25 - April 2024',
      content: ['Calculation improvments'], // No translation
      isCollapsed: true
    });

    this.releaseNotes.push({
      title: 'Release 2.24 - January 2024',
      content: ['General improvments', 'Calculation improvments', 'ETA calculation fixes', 'New registration process'], // No translation
      isCollapsed: true
    });

    this.releaseNotes.push({
      title: 'Release 2.23 - November 2023',
      content: ['Added support for Turkish language in report', 'Update Tracking data', 'CCMS 2.0 updates'], // No translation
      isCollapsed: true
    });

    this.releaseNotes.push({
      title: 'Release 2.22 - July 2023',
      content: ['Bug fixes for AS/NZS 2327'], // No translation
      isCollapsed: true
    });

    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-11'),
      content: [
        this.translate.instant('whats-new-engineering-judgement'),
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-10'),
      content: [
        this.translate.instant('whats-new-4-rows'),
        this.translate.instant('whats-new-new-regions-greece-slovenia-serbia-chroatia'),
        this.translate.instant('whats-new-bug-fixes-eta-upgrade'),
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-9'),
      content: [
        this.translate.instant('whats-new-eta-update'),
        this.translate.instant('whats-new-israel'),
        this.translate.instant('whats-new-content-profile-decks'),
        this.translate.instant('whats-new-top-stiffener-height'),
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-8'),
      content: [
        this.translate.instant('whats-new-bug-fix-aisc'),
        this.translate.instant('whats-new-equation-report-uae'),
        this.translate.instant('whats-new-ux-improvement')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-7'),
      content: [
        this.translate.instant('whats-new-new-look-n-feel')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-6'),
      content: [
        this.translate.instant('whats-new-asnzs-standard'),
        this.translate.instant('whats-new-meta-adaptation'),
        this.translate.instant('whats-new-singapore-concrete-grades'),
        this.translate.instant('whats-new-american-four-connectors-in-a-row')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-5'),
      content: [
        this.translate.instant('whats-new-general-improvements'),
        this.translate.instant('whats-new-update-technical-document')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-4'),
      content: [
        this.translate.instant('whats-new-release-of-american-standard'),
        this.translate.instant('whats-new-introduce-new-countries'),
        this.translate.instant('whats-new-introduce-american-decks')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-3'),
      content: [
        this.translate.instant('whats-new-content-new-annexes'),
        this.translate.instant('whats-new-content-new-decks')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-2'),
      content: [
        this.translate.instant('whats-new-content-general-improvements')
      ],
      isCollapsed: true
    });
    this.releaseNotes.push({
      title: this.translate.instant('whats-new-release-1'),
      content: [
        this.translate.instant('whats-new-content-automatic-zoning'),
        this.translate.instant('whats-new-content-slab-openings'),
        this.translate.instant('whats-new-content-user-degree'),
        this.translate.instant('whats-new-content-profile-decks'),
        this.translate.instant('whats-new-content-edge-spacing'),
        this.translate.instant('whats-new-content-whats-new'),
        this.translate.instant('whats-new-content-nps'),
      ],
      isCollapsed: true
    });
  }
}
